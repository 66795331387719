import { cleanFormData, decrypt } from '@/utils'
import { CURRENT_BRANCH, AUTHENTICATED, LOGGED_IN, RECOVERY_PASSWORD, TOKEN } from '@/utils/constants'
import axios from 'axios'
import Cookies from 'js-cookie'

export const api = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

api.interceptors.request.use(function (config) {
  const token = decrypt(Cookies.get(TOKEN))
  const auth = [LOGGED_IN, RECOVERY_PASSWORD]
  config.baseURL = auth.includes(decrypt(Cookies.get(AUTHENTICATED))) ? process.env.NEXT_PUBLIC_API_AUTHENTICATED_URL : process.env.NEXT_PUBLIC_API_URL
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (Cookies.get(CURRENT_BRANCH)) {
    const currentBranch = JSON.parse(Cookies.get(CURRENT_BRANCH))
    if (currentBranch) {
      config.headers['X-Branch'] = currentBranch.id
    }
  }
  return config
})

export const post = async (url, data = {}) => {
  return new Promise((resolve, reject) => {
    api.post(url, cleanFormData(data))
      .then(response => resolve(response.data))
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        reject(errorMessage)
      })
  })
}

export const post2 = async (url, data = {}) => {
  return new Promise((resolve, reject) => {
    api.post(url, cleanFormData(data))
      .then(response => resolve(response.data))
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        const status = error.response.status
        const response = {
          status,
          error: errorMessage
        }
        reject(response)
      })
  })
}

export const get = async (url, data = {}) => {
  return new Promise((resolve, reject) => {
    api.get(url, { params: cleanFormData(data) }) // https://axios-http.com/docs/example
      .then(response => resolve(response.data))
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        reject(errorMessage)
      })
  })
}
