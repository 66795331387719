import { post } from '../../api'

export async function registerAsync (formData) {
  return await post('authenticate/register', formData)
}

export async function verifyCodeAsync (formData) {
  return await post('user/register/verify-code', formData)
}

export async function skipValidationAsync (formData) {
  return await post('user/register/skip-validation', formData)
}

export async function resendCodeAsync (formData) {
  return await post('user/register/resend-code', formData)
}
